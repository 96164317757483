@charset "utf-8";

.main-menu {
	margin: 0;
	padding: 0;
	font-size: 20px;
	line-height: 22px;
	font-family: 'PTSans-NarrowBold';
	letter-spacing: 1px;
	text-transform: uppercase;
	>li {
		list-style: none;
		float: left;
		margin: 0;
		padding: 8px 8px 8px 6px;
		line-height: 26px;

		&.active {
			background-color: #f15c22;
			a {
				border-bottom: none;
				padding: 0 0 1px 0;
			}
		}

		&:first-child {
			margin-left: 0;
			background: url("./../images/first-child-menu.png") center center no-repeat;
			width: 25px;
			-webkit-border-radius: 3px 0 0 3px;
			-moz-border-radius: 3px 0 0 3px;
			border-radius: 3px 0 0 3px;
			a {
				background-color: transparent;
				text-indent: -9000%;
				border-bottom: none;
				padding: 0 0 1px 0;
			}
		}

		&.drop-down {
			position: relative;

			&:hover {
				background-color: #878789;

				.main-menu-level2 {
					display: block;
				}

				>a {
					&:hover {
						padding-bottom: 1px;
					}
				}
			}

			&.active {
				background-color: #f15c22;

				>a {
					border-bottom: #fff solid 1px;
					padding: 0;

					&:hover {
						border-bottom: none;
						padding-bottom: 1px;
					}
				}
			}
			.main-menu-level2 {
				position: absolute;
				top: 100%;
				left: 0;
				display: none;
				float: left;
				min-width: 600px;
				padding: 15px 0 15px 20px;
				margin: 0;
				list-style: none;
				background-color: #878789;
				border: 0;
				color: #f4f5f6;
				-webkit-border-radius: 0;
				-moz-border-radius: 0;
				border-radius: 0;
				-webkit-box-shadow: none;
				-moz-box-shadow: none;
				box-shadow: none;
				-webkit-background-clip: padding-box;
				-moz-background-clip: padding;
				background-clip: padding-box;
				z-index: 2000;
				text-transform: none;
				letter-spacing: normal;

				li {
					float: left;
					width: 300px;

					a {
						display: block;
						padding: 8px 20px 8px 0;
						clear: both;
						text-decoration: underline;
						font-family: 'PTSans-Narrow';
						line-height: 24px;
						color: #ffffff;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						-o-text-overflow: ellipsis;

						&:hover,
						&:focus,
						&.active {
							background: none;
							color: #ffffff;
							text-decoration: none;
						}
					}
				}
			}
		}

		>a {
			display: block;
			color: #ffffff;
			text-decoration: none;
			border-bottom: #969698 solid 1px;
			padding: 0;
			text-shadow: none;
			line-height: 22px;
			-webkit-border-radius: 0;
			-moz-border-radius: 0;
			border-radius: 0;
			margin: 0;
			&:hover,
			&:focus {
				color: #ffffff;
				border-bottom: none;
				background: transparent;
			}
		}
	}
}

.main-menu-mobile__container {
	position: absolute;
	top: 43px;
	width: 260px;
	z-index: 100;
	left: 0;
	background-color: #58585a;
	overflow: scroll;
	-webkit-overflow-scrolling: touch;
	&.in {
			min-height: 100vh;
	}
}

.main-menu-mobile {
	float: none;
	position: relative;
	left: 0;
	display: block;
	margin: 0;
	font-size: 16px;
	line-height: 18px;
	padding: 10px;
	font-family: PTSans-NarrowBold;
	letter-spacing: 1px;
	text-transform: uppercase;
	>li {
		border-radius: 3px;
		&.active {
			background-color: #f15c22;
			a {
				background: #f15c22;
			}
		}
		&.drop-down {
			&.active {
				background-color: transparent;
				.main-menu-level2 {
					display: block;
				}
			}
			.main-menu-level2 {
				display: none;
				position: static;
				top: auto;
				left: auto;
				float: none;
				max-width: none;
				padding: 0;
				margin: 0 15px;
				background-color: transparent;
				border: none;
				-webkit-border-radius: 0;
				-moz-border-radius: 0;
				border-radius: 0;
				-webkit-box-shadow: none;
				-moz-box-shadow: none;
				box-shadow: none;
				list-style: none;
				letter-spacing: normal;
				li {
					a {
						display: block;
						font-family: 'PTSans-Narrow';
						color: #fff;
						background: transparent;
						padding: 2px;
						white-space: normal;
						line-height: 24px;
						overflow: hidden;
						text-overflow: ellipsis;
						clear: both;
						text-decoration: underline;
						text-transform: none;
						letter-spacing: normal;
					}
				}
			}
		}
		>a {
			display: block;
			color: #FFF;
			background: transparent;
			border-bottom: none;
			-webkit-border-radius: 3px;
			-moz-border-radius: 3px;
			border-radius: 3px;
			padding: 5px;
			text-decoration: none;
			text-shadow: none;
			line-height: 22px;
			font-weight: bold;
			float: none;
			&:hover,
			&:focus {
				background: #878789;
				color: #ffffff;
			}
		}
	}
}

@media (min-width: 1200px) {
	.main-menu {
		>li {
			margin: 0 6px;
			padding: 8px 10px 8px 10px;

			&.drop-down {
				background: url("./../images/drop-bg.png") right 17px no-repeat;
				padding-right: 26px;
			}
		}
	}
}
