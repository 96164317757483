@charset "utf-8";
@import "~raty-js/lib/jquery.raty";
@import "./mixins";
@import "./menu";

.discount {
	@include product-icon;
	top: 10px;
	right: 10px;
	background: #e33811;
	color: #FFF; 
}

.hit__cat {
	@include product-icon;
	bottom: 10px;
	right: 10px;
	background: #e59e04;
	color: #FFF;
}

.new__cat {
	@include product-icon;
	top: 10px;
	right: 10px;
	background: #32a372;
	color: #FFF;
}

.fancybox-lock body {
    overflow: visible !important;
}
