@charset "utf-8";

@mixin product-icon {
	font-size: 17px;
	position: absolute;
	font-weight: bold;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	height: 50px;
	width: 50px;
	text-align: center;
	line-height: 50px;
}
