












































































































































































































































































































































































































































































































































label {
	&.error {
		color: #eb0600;
	}
}
input {
	&.is-invalid {
		border: 1px solid #eb0600;
		color: #eb0600;
	}
}
select {
	&.is-invalid {
		border: 1px solid #eb0600;
	}
}
.calculator__container {
	.form-horizontal {
		background: #fff;
		padding: 20px;
		margin: 0 0 20px 0;
		display: inline-block;
		border: 1px solid #e5e5e5;
		font-size: 14px;
		.control-label {
			width: 220px;
		}
		.controls {
			margin-left: 240px;
			width: 250px;
		}
		input[type="text"],
		input[type="number"],
		select {
			width: 100%;
			display: block;
			font-size: 15px;
			line-height: 22px;
			height: inherit;
		}
		input {
			max-width: 240px;
		}
	}
	label {
		font-size: 15px;
		line-height: 21px;
		margin: 0;
		padding: 2px 0 0 0;
	}
	h5 {
		margin: 0 0 15px 0;
		font-size: 17px;
		line-height: 22px;
	}
	.text-center {
		margin-top: 15px;
	}
	.radio {
		position: relative;
		padding-left: 35px;
		margin-bottom: 10px;
		min-height: 25px;
		input[type=radio] {
			top: 2px;
			position: absolute;
			opacity: 0;
			clip: rect(0, 0, 0, 0);
			z-index: -1;
			&:checked {
				+ label {
					&:after,
					&:hover:after {
						background-image: url(../../images/radio-on.svg);
					}
				}
			}
		}
		label {
			&:after {
				content: '';
				display: inline-block;
				position: absolute;
				width: 25px;
				height: 25px;
				background-image: url(../../images/radio-off.svg);
				background-repeat: no-repeat;
				left: 0;
				top: 0;
				margin-right: 10px;
			}
		}
	}
	.checkbox {
		position:relative;
		padding-left: 35px;
		margin-bottom: 10px;
		margin-top: 20px;
		min-height: 25px;
		label {
			&:after {
				content: '';
				display: inline-block;
				position: absolute;
				width: 25px;
				height: 25px;
				background-image: url(../../images/checkbox-off.svg);
				background-repeat: no-repeat;
				left: 0;
				top: 0;
				margin-right: 10px;
			}
		}
		input[type=checkbox] {
			top: 2px;
			position: absolute;
			opacity: 0;
			clip: rect(0, 0, 0, 0);
			z-index: -1;
			&:checked {
				+ label {
					&:after,
					&:hover:after {
						background-image: url(../../images/checkbox-on.svg);
					}
				}
			}
		}
	}

	@media (max-width: 767px) {
		.form-horizontal {
			padding: 15px;
			display: block;
			margin: 0 -15px 20px -15px;
			border: none;
			.control-label {
				width: inherit;
				float: none;
				margin-bottom: 5px;
				text-align: left;
			}
			.controls {
				margin-left: 0;
			}
		}
	}
}
